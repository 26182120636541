<template>
  <div class="thank-you__icon-wrapper">
    <Ui2Icon name="check-circle" :width="28" :height="28" class="thank-you__icon" />
  </div>
  <div class="thank-you__title">
    {{ $mopI18n.t('form.thank_you.step.title') }}
  </div>
  <div class="thank-you__subtitle">
    {{ $mopI18n.t('form.thank_you.step.subtitle') }}
  </div>
</template>

<style lang="scss" scoped>
.thank-you__icon-wrapper {
  padding: $space-16;
  background-color: $color-surface-success;
  border-radius: $border-radius-rounded-full;
  display: inline-block;
}

.thank-you__icon {
  display: block;
  color: $color-text-success;
}

.thank-you__title {
  @include v2-text-style('4xl', 'highlight');

  margin-top: $space-24;
}

.thank-you__subtitle {
  @include v2-text-style('sm');

  color: $color-text-body-secondary;
  margin-top: $space-12;
}
</style>
